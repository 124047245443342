/**
 * @author Salma Hefnawy
 * @date 2022-10-20
 * @description implementation of orascom-shopper-app endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */

const endpointsPrefix = process.env.NX__SHOPPER_ENDPOINT_PREFIX ?? '';

export const ENDPOINTS = {
  getHomeMetaData: {
    path: `${endpointsPrefix}/pages/homepage`,
    method: 'GET',
  },
  getAboutUsMetaData: {
    path: `${endpointsPrefix}/pages/about`,
    method: 'GET',
  },
  getPrivacyPolicy: {
    path: `${endpointsPrefix}/pages/privacy-policy`,
    method: 'GET',
  },
  getTermsAndConditions: {
    path: `${endpointsPrefix}/pages/terms-and-conditions`,
    method: 'GET',
  },
  getFAQs: {
    path: `${endpointsPrefix}/pages/faqs`,
    method: 'GET',
  },
  getCountryInfo: {
    url: (countrySlug: string): string =>
      `${endpointsPrefix}/countries/${countrySlug}`,
    method: 'GET',
  },
  getDestinationInfo: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}`,
    method: 'GET',
  },
  getDestinationTestimonials: {
    url: (destinationId: string): string =>
      `${endpointsPrefix}/destinations/${destinationId}}/testimonials`,
    method: 'GET',
  },
  getDestinationProjects: {
    url: (destinationId: number): string =>
      `${endpointsPrefix}/destinations/${destinationId}}/projects`,
    method: 'GET',
  },
  getDestinationLocations: {
    url: (destinationId: number): string =>
      `${endpointsPrefix}/destinations/${destinationId}/locations`,
    method: 'GET',
  },
  getDestinationUnitTypes: {
    url: (destinationId: number): string =>
      `${endpointsPrefix}/destinations/${destinationId}}/unit-types`,
    method: 'GET',
  },
  getDestinationConstructionUpdates: {
    url: (destinationId: number): string =>
      `${endpointsPrefix}/destinations/${destinationId}}/construction-updates`,
    method: 'GET',
  },
  getCountries: {
    url: (countryId?: string): string =>
      `${endpointsPrefix}/countries?${
        countryId ? `country_id=${countryId}` : ''
      }`,
    method: 'GET',
  },
  getNews: {
    path: `${endpointsPrefix}/news`,
    method: 'GET',
  },
  getNewsDetails: {
    url: (newsSlug: string): string => `${endpointsPrefix}/news/${newsSlug}`,
    method: 'GET',
  },

  getCountriesInvestments: {
    path: `${endpointsPrefix}/countries/investments`,
    method: 'GET',
  },
  getUserReservationDetails: {
    path: `${endpointsPrefix}/details`,
    method: 'GET',
  },

  /**
   * This endpoint will be removed
   */
  getCountryUnits: {
    url: (countryId: string, filters?: string): string =>
      `${endpointsPrefix}/countries/${countryId}/units${filters ?? ''}`,
    method: 'GET',
  },

  getUnits: {
    url: (filters?: string): string =>
      `${endpointsPrefix}/units${filters ?? ''}`,
    method: 'GET',
  },

  getUnitDetails: {
    url: (unitId: string): string => `${endpointsPrefix}/units/${unitId}`,
    method: 'GET',
  },
  getUnitReservationDetails: {
    url: (unitId: string): string =>
      `${endpointsPrefix}/units/${unitId}/reservation-details`,
    method: 'GET',
  },
  getUnitPayments: {
    url: (unitId: string): string =>
      `${endpointsPrefix}/units/${unitId}/payment-terms`,
    method: 'GET',
  },

  getFilterValues: {
    url: `${endpointsPrefix}/filters`,
    method: 'GET',
  },
  getDestinationFacilities: {
    url: (destinationId: number): string =>
      `${endpointsPrefix}/destinations/${destinationId}/facilities`,
    method: 'GET',
  },
  contactUs: {
    path: `${endpointsPrefix}/sales-request`,
    method: 'POST',
  },
  submitShopperLead: {
    path: `${endpointsPrefix}/leads`,
    method: 'POST',
  },
  salesContacts: {
    path: `${endpointsPrefix}/contact-info`,
    method: 'GET',
  },
  compareUnits: {
    path: `${endpointsPrefix}/units/compare`,
    method: 'GET',
  },
  getWishList: {
    path: `${endpointsPrefix}/user/saved-units`,
    method: 'GET',
  },
  addToWishList: {
    url: (unitId: string): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'POST',
  },
  removeFromWishList: {
    url: (unitId: string): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'DELETE',
  },
  login: {
    path: `${endpointsPrefix}/login`,
    method: 'POST',
  },
  checkUserDetails: {
    path: `${endpointsPrefix}/user-data-availability`,
    method: 'GET',
  },
  register: {
    path: `${endpointsPrefix}/register`,
    method: 'POST',
  },
  getCurrentUser: {
    path: `${endpointsPrefix}/me`,
    method: 'GET',
  },
  addDraftSale: {
    url: (unitId: number): string => `${endpointsPrefix}/units/${unitId}/sale`,
    method: 'POST',
  },
  reserveTransactionCode: {
    url: (unitId: number, saleId: number): string =>
      `${endpointsPrefix}/units/${unitId}/${saleId}/reserve`,
    method: 'POST',
  },
  reserveAttachment: {
    url: (unitId: number, saleId: number): string =>
      `${endpointsPrefix}/units/${unitId}/${saleId}/attach`,
    method: 'POST',
  },
  getSalePaymentToken: {
    url: (unitId: number, saleId: number) =>
      `${endpointsPrefix}/units/${unitId}/${saleId}/payment`,
    method: 'POST',
  },
  getSaleInstallments: {
    url: (saleId: string) => `${endpointsPrefix}/sales/${saleId}/installments`,
    method: 'GET',
  },
  getUnitReservationNationalities: {
    path: `${endpointsPrefix}/sales-nationalities`,
    method: 'GET',
  },
  getUnitReservationCountries: {
    path: `${endpointsPrefix}/sales-countries`,
    method: 'GET',
  },
  getUnitReservationCities: {
    path: `${endpointsPrefix}/sales-cities`,
    method: 'GET',
  },
  getUnitReservationOccupations: {
    path: `${endpointsPrefix}/sales-occupations`,
    method: 'GET',
  },
  getUnitReservationConnections: {
    path: `${endpointsPrefix}/sales-connections`,
    method: 'GET',
  },
  updateReservedUserInfo: {
    url: (unitId: number, saleId: number) =>
      `${endpointsPrefix}/units/${unitId}/${saleId}/update-info`,
    method: 'POST',
  },
  downloadReservationForm: {
    url: (unitId: number, saleId: number) =>
      `${endpointsPrefix}/units/${unitId}/${saleId}/reservation-form`,
    method: 'POST',
  },
  downloadOmanSalesOffer: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/sales-offer`,
    method: 'GET',
  },
  finishUnitReservation: {
    url: (unitId: number, saleId: number) =>
      `${endpointsPrefix}/units/${unitId}/${saleId}/finish-sale`,
    method: 'PATCH',
  },
  getCurrencies: {
    url: (sourceCurrency: string, currencies: string) =>
      `${endpointsPrefix}/currency-rates?source=${sourceCurrency}&currencies=${currencies}`,
    method: 'GET',
  },
  getActivePromotion: {
    url: `${endpointsPrefix}/promotions`,
    method: 'GET',
  },
};

const preDeliveryPrefix = process.env.NX__PRE_DELIVERY_ENDPOINT_PREFIX ?? '';

export const PRE_DELIVERY_ENDPOINTS = {
  getUnitDetails: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/user/units/${unitId}`,
    method: 'GET',
  },
  ownerCards: {
    path: `${preDeliveryPrefix}/user/cards`,
    method: 'GET',
  },
  getMyUnits: {
    path: `${preDeliveryPrefix}/user/units`,
    method: 'GET',
  },
  getIssueTypes: {
    path: `${preDeliveryPrefix}/issue-types`,
    method: 'GET',
  },
  submitAssistanceRequest: {
    path: `${preDeliveryPrefix}/user/assistance-request`,
    method: 'POST',
  },
  getUnitPayments: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/user/units/${unitId}/sale`,
    method: 'GET',
  },
  getUnitPaymentBreakdown: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/user/units/${unitId}/payment-breakdown`,
    method: 'GET',
  },
  getOffers: {
    path: `${preDeliveryPrefix}/user/offers`,
    method: 'GET',
  },
  getMyReferrals: {
    path: `${preDeliveryPrefix}/user/referrals`,
    method: 'GET',
  },
  submitReferral: {
    path: `${preDeliveryPrefix}/user/submit-referral`,
    method: 'POST',
  },
  submitServiceRequest: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/units/${unitId}/customer-requests`,
    method: 'POST',
  },
  submitResaleRequest: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/units/${unitId}/resell-assistance`,
    method: 'POST',
  },
  getServiceRequests: {
    path: `${preDeliveryPrefix}/user/customer-requests`,
    method: 'GET',
  },
  getCurrencies: {
    path: `${preDeliveryPrefix}/currencies`,
    method: 'GET',
  },
  changeContactDetailsRequest: {
    path: `${preDeliveryPrefix}/user/change-contact-info`,
    method: 'POST',
  },
  getNews: {
    path: `${preDeliveryPrefix}/user/news`,
    method: 'GET',
  },
  getAddon: {
    url: (addonId: string): string => `${preDeliveryPrefix}/addons/${addonId}`,
    method: 'GET',
  },
  getUnitAddons: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/units/${unitId}/addons`,
    method: 'GET',
  },
  submitAddonRequest: {
    url: (unitId: string): string =>
      `${preDeliveryPrefix}/units/${unitId}/addon`,
    method: 'POST',
  },
};
