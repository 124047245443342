/* eslint-disable @nx/enforce-module-boundaries */
import styles from './reservation-fee.module.scss';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikValues } from 'formik';
import * as Yup from 'yup';
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';
import {
  Common,
  CountriesEnum,
  PaymentMethods,
  ReservationStateEnum,
  ReserveTransactionCodeParams,
} from '@orascom/api-interfaces';
import CreditCardIcon from '../../assets/icons/credit-card--blue.svg?react';
import BankTransferIcon from '../../assets/icons/bank.svg?react';
import InfoIcon from '../../assets/icons/info.svg';
import { GoldButton, NavyButton } from '../common/button/button';
import UnitReservationFee from '../unit-reservation-fee/unit-reservation-fee';
import { Unit as UnitUtils } from '../../utils/unit.utils';
import { BankForm } from './bank-form';
import { useOnlinePayment } from './use-online-payment';
import { Loader } from '@orascom/common-components';
import { toast } from 'react-toastify';
import { DraftSaleResponse } from '../../definitions/interfaces/common.interface';
import Numeral from 'numeral';
import {
  showDestBankTransfer,
  showOnlinePayment,
} from '../../definitions/consts/envConstants';

/* eslint-disable-next-line */
export interface ReservationFeeProps {
  page?: 'reservation' | 'buy-addon';
  updateFormSubmissionState?: (formSubmissionState: boolean) => void;
  unitCountry?: CountriesEnum;
}

export function ReservationFee(props: Readonly<ReservationFeeProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { reservationUnitDetails, setReservationState, sale } =
    useContext(RESERVATION_CONTEXT);
  const ReservationFeeFormSchema = Yup.object().shape({
    swiftCode: Yup.string().when('paymentMethod', {
      is: 'bank-transfer',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    bankReceipt: Yup.mixed().when('paymentMethod', {
      is: (val: string) => {
        return val === 'bank-transfer' || val === 'bank-receipt';
      },
      then: Yup.mixed().required(t('fieldIsRequired')),
    }),
  });
  const onOnlinePaymentSucceed = (payment: unknown) => {
    console.log('payment succeed', payment);
  };

  const onOnlinePaymentFailed = (payment: unknown) => {
    console.log('payment failed', payment);
  };

  const [payOnline] = useOnlinePayment(
    props.unitCountry,
    reservationUnitDetails?.project?.slug,
    reservationUnitDetails?.id,
    sale?.id,
    Math.ceil(Number(sale?.reservation_amount)),
    onOnlinePaymentSucceed,
    onOnlinePaymentFailed
  );

  const submitButtonText =
    props.page !== 'buy-addon' ? t('payReservationFee') : t('purchaseAddon');

  const handlePayReservation = (values: FormikValues) => {
    let promise:
      | Promise<[Common, DraftSaleResponse]>
      | Promise<DraftSaleResponse>
      | Promise<unknown> = Promise.resolve();

    if (reservationUnitDetails && sale) {
      if (
        values.paymentMethod === 'bank-transfer' ||
        values.paymentMethod === 'bank-receipt'
      ) {
        const newReservation: ReserveTransactionCodeParams = {
          currency_code: reservationUnitDetails?.currency,
          currency_rate: 1,
          reservation_amount: Math.ceil(Number(sale.reservation_amount)),
          reservation_payment_method: PaymentMethods.BANK_TRANSFER,
          transaction_code: values.swiftCode ?? '',
        };
        promise = promise
          .then(() => {
            return UnitUtils.reserveAttachment(
              reservationUnitDetails.id,
              sale.id,
              '0',
              values.bankReceipt
            );
          })
          .catch((err) => {
            console.log(err);
            toast.error('Failed to upload bank receipt');
            return Promise.reject();
          });

        promise = promise.then(() => {
          return UnitUtils.reserveTransactionCode(
            reservationUnitDetails?.id,
            sale?.id,
            newReservation
          );
        });
      } else if (values.paymentMethod === 'card') {
        try {
          payOnline();
        } catch (error) {
          console.log(error);
          toast.error("This unit doesn't support online payment");
        }
      }

      if (
        values.paymentMethod === 'bank-transfer' ||
        values.paymentMethod === 'bank-receipt'
      ) {
        setLoading(true);
        promise
          .then(() => {
            if (props.page === 'buy-addon' && props.updateFormSubmissionState) {
              props.updateFormSubmissionState(true);
            }
            setReservationState?.(
              ReservationStateEnum.PAY_RESERVATION_FEE_DONE
            );
          })
          .finally(() => setLoading(false));
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  // TODO: this is a tmp fix, we will be depending on backend to show/hide bank transfer later
  const bankTransferExcluded = ['o-west', 'makadi-heights', 'el-gouna'];
  const excludeBankTransfer = bankTransferExcluded.includes(
    reservationUnitDetails?.project.destination.slug as string
  );
  const includeOnlinePayment = showOnlinePayment(
    reservationUnitDetails?.project.destination?.country?.slug ?? ''
  );
  const hideDestBankTransfer = !showDestBankTransfer && excludeBankTransfer;
  return (
    <div className={styles['reservation-fee']}>
      {props.page !== 'buy-addon' && (
        <UnitReservationFee
          fee={Math.ceil(Number(sale?.reservation_amount))}
          inForm={true}
          currency={reservationUnitDetails?.currency}
        />
      )}

      <div className={styles['reservation-note']}>
        <label>
          {props.page !== 'buy-addon' ? t('reservationFee') : t('addonPrice')}
        </label>
        <p>
          {Numeral(sale?.reservation_amount).format('0,0')}{' '}
          <span className="currency">
            {t(reservationUnitDetails?.currency ?? '')}
          </span>
        </p>
      </div>
      <h1 className="reservation-form-title">{t('choosePaymentMethod')}</h1>
      {props.unitCountry === CountriesEnum.OMAN ? (
        <p className={styles['disclaimer']}>
          {' '}
          <img
            src={InfoIcon}
            role="presentation"
            alt="disclaimer icon"
            style={{ height: '20px' }}
          />
          <span>{t('omanPaymentDisclaimer')}</span>{' '}
        </p>
      ) : null}
      <Formik
        initialValues={{
          paymentMethod: 'card',
          cardNumber: '',
          expiryDate: '',
          cvv: '',
          nameOnCard: '',
          swiftCode: '',
          bankReceipt: {},
        }}
        validationSchema={ReservationFeeFormSchema}
        onSubmit={(values) => {
          handlePayReservation(values);
        }}
      >
        {({ errors, touched, setFieldValue, values, isValid }) => (
          <Form className="reservation-form">
            <div role="group" className="reservation-radio-buttons--big">
              <div>
                {includeOnlinePayment ? (
                  <label>
                    <div>
                      <p>
                        <CreditCardIcon />
                        {t('cardMethod')}
                      </p>
                      <Field type="radio" name="paymentMethod" value="card" />
                    </div>
                  </label>
                ) : null}
                {!hideDestBankTransfer && (
                  <label>
                    <div>
                      <p>
                        <BankTransferIcon />
                        {t('bankTransferMethod')}
                      </p>
                      <Field
                        type="radio"
                        name="paymentMethod"
                        value="bank-transfer"
                      />
                    </div>
                  </label>
                )}
                {/* {props.page !== 'buy-addon' && (
                  <label>
                    <div>
                      <p>
                        <BankReceiptIcon />
                        {t('bankReceiptMethod')}
                      </p>
                      <Field
                        type="radio"
                        name="paymentMethod"
                        value="bank-receipt"
                      />
                    </div>
                  </label>
                )} */}
              </div>
            </div>
            <BankForm
              touched={touched}
              errors={errors}
              values={values}
              page={props.page}
              setFieldValue={setFieldValue}
            />

            <div className="reservation-form-buttons">
              <GoldButton disabled={!isValid} type="submit">
                {submitButtonText}
                {isValid}
              </GoldButton>

              <NavyButton asLink to="/">
                {t('saveAndContinueLater')}
              </NavyButton>
            </div>
          </Form>
        )}
      </Formik>
      <form
        action={process.env.NX_WSPAY_PAYMENT_URL}
        method="post"
        style={{ visibility: 'hidden' }}
        id="montenegro-online-payment-form"
      >
        <input type="hidden" name="ShopID" id="shop-id" value="" />
        <input
          type="hidden"
          name="ShoppingCartID"
          id="shopping-cart-id"
          value=""
        />
        <input type="hidden" name="Version" id="version" value="" />
        <input type="hidden" name="TotalAmount" id="total-amount" value="" />
        <input type="hidden" name="CancelURL" id="cancel-URL" value="" />
        <input type="hidden" name="ReturnURL" id="return-URL" value="" />
        <input
          type="hidden"
          name="ReturnErrorURL"
          id="return-error-URL"
          value=""
        />
        <input type="hidden" name="Signature" id="signature" value="" />
      </form>
    </div>
  );
}

export default ReservationFee;
