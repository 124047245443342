import { ENDPOINTS } from '../api/endpoints';

export const CURRENCIES = ['EGP', 'USD', 'EUR', 'OMR', 'AED'];

export async function fetchExchangeRates(
  sourceCurrency: string
): Promise<{ [key: string]: number } | null> {
  const baseUrl = process.env.NX__API_ENDPOINT;
  const getEndpoint = ENDPOINTS.getCurrencies.url;
  const currencies = CURRENCIES.join(',');
  try {
    const response = await fetch(
      baseUrl + getEndpoint(sourceCurrency, currencies)
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch exchange rates: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    return null;
  }
}
