import { UnitPaymentTerms } from '@orascom/api-interfaces';
import { useState } from 'react';

export function usePaymentPlanCalculator() {
  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms | null>(null);

  const onSelectDownPayment = (
    downPayment: number,
    paymentTerms: UnitPaymentTerms[]
  ) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.downpayment_percent === downPayment
    );
    const availablePaymentTermsWithSelectedDuration =
      availablePaymentTerms.filter(
        (term) =>
          term.installment_durationin_years ===
          selectedPaymentTerms?.installment_durationin_years
      );

    if (!availablePaymentTermsWithSelectedDuration.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }

    const availablePaymentTermsWithSelectedFreq =
      availablePaymentTermsWithSelectedDuration.filter(
        (term) =>
          term.installment_frequency ===
          selectedPaymentTerms?.installment_frequency
      );

    if (!availablePaymentTermsWithSelectedFreq.length) {
      setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
      return;
    }

    setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
  };

  const onSelectFrequency = (
    freq: number,
    paymentTerms: UnitPaymentTerms[]
  ) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.installment_frequency === freq
    );

    const availablePaymentTermsWithSelectedDuration =
      availablePaymentTerms.filter(
        (term) =>
          term.installment_durationin_years ===
          selectedPaymentTerms?.installment_durationin_years
      );

    if (!availablePaymentTermsWithSelectedDuration.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }

    const availablePaymentTermsWithSelectedDownPayment =
      availablePaymentTermsWithSelectedDuration.filter(
        (term) =>
          term.downpayment_percent === selectedPaymentTerms?.downpayment_percent
      );

    if (!availablePaymentTermsWithSelectedDownPayment.length) {
      setSelectedPaymentTerms(availablePaymentTermsWithSelectedDuration[0]);
      return;
    }

    setSelectedPaymentTerms(availablePaymentTermsWithSelectedDownPayment[0]);
  };

  const onSelectDuration = (freq: number, paymentTerms: UnitPaymentTerms[]) => {
    const availablePaymentTerms = paymentTerms.filter(
      (term) => term.installment_durationin_years === freq
    );

    const availablePaymentTermsWithSelectedDownPayment =
      availablePaymentTerms.filter(
        (term) =>
          term.downpayment_percent === selectedPaymentTerms?.downpayment_percent
      );

    if (!availablePaymentTermsWithSelectedDownPayment.length) {
      setSelectedPaymentTerms(availablePaymentTerms[0]);
      return;
    }

    const availablePaymentTermsWithSelectedFreq =
      availablePaymentTermsWithSelectedDownPayment.filter(
        (term) =>
          term.installment_frequency ===
          selectedPaymentTerms?.installment_frequency
      );

    if (!availablePaymentTermsWithSelectedFreq.length) {
      setSelectedPaymentTerms(availablePaymentTermsWithSelectedDownPayment[0]);
      return;
    }

    setSelectedPaymentTerms(availablePaymentTermsWithSelectedFreq[0]);
  };

  return {
    onSelectDownPayment,
    onSelectFrequency,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
  };
}

export default usePaymentPlanCalculator;
