/* eslint-disable @nx/enforce-module-boundaries */
import { UnitDetails } from '@orascom/api-interfaces';
import Numeral from 'numeral';
import styles from './my-unit-card.module.scss';
import { useTranslation } from 'react-i18next';
import { NavyButton } from '../../common/button/button';
import { ROUTES } from '../../../api/routes';
import { Link } from 'react-router-dom';
import PaymentProgress from '../payment-progress/payment-progress';
import { GoldButton, Loader } from '@orascom/common-components';
import { useUnitPayment } from '../../../../src/pages/pre-delivery/hooks/use-unit-payment';
import unitImagePlaceHolder from '../../../assets/images/uni-details.webp';
import { showPreDeliveryPay } from 'apps/orascom-shopper-app/src/definitions/consts/envConstants';
import { calculateTimeLeft } from 'apps/orascom-shopper-app/src/utils/common.utils';

export interface MyUnitCardProps {
  unitDetails: UnitDetails;
  unitPage: 'my-units' | 'unit-payments' | 'unit-details';
  executeScroll?: () => void | null;
}

export function MyUnitCard({
  unitDetails,
  unitPage,
}: Readonly<MyUnitCardProps>) {
  const { t } = useTranslation();

  const [unitInstallments, loading, sale] = useUnitPayment(
    unitDetails.id.toString()
  );

  if (loading) {
    return <Loader />;
  }

  const unPaidInstallments = unitInstallments?.installments?.filter(
    (installment) => !installment.paid
  );

  const hidePaymentImageLink = !showPreDeliveryPay && unitPage !== 'my-units';

  const timeLeft = calculateTimeLeft(unitDetails.delivery_date);
  return (
    <div className={styles['details']}>
      <div className={styles['details-info']}>
        <h1 className="orascom__title">
          {`${unitDetails?.project?.destination?.name} - ${unitDetails?.project?.name}`}
        </h1>
        <h2 className="orascom__sub-title">{unitDetails?.unit_type?.name}</h2>
        {unPaidInstallments?.length ? (
          <div className={styles['details-info-installments']}>
            <div className={styles['details-info-installment']}>
              <label>{t('nextInstallment')}</label>
              <p>
                {Numeral(unPaidInstallments[0].payment_value).format('0,0')}{' '}
                <span>{unPaidInstallments[0].currency}</span>
              </p>
            </div>
            <div className={styles['details-info-installment']}>
              <label>{t('nextInstallmentDue')}</label>
              <p>
                {new Date(
                  unPaidInstallments[0].payment_date * 1000
                ).toLocaleDateString('en-GB', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </div>
          </div>
        ) : null}
        {unitPage !== 'unit-payments' && (
          <div className={styles['details-info-items']}>
            <div className={styles['details-info-item']}>
              <label>{t('unitName')}</label>
              <p>{unitDetails.name}</p>
            </div>
            <div className={styles['details-info-item']}>
              <label>{t('constructionStatus')}</label>
              <p>
                {unitDetails.construction_status === 'completed' ? (
                  <span
                    className={`${styles['status']} ${styles['completed']}`}
                  ></span>
                ) : (
                  <span
                    className={`${styles['status']} ${styles['on-going']}`}
                  ></span>
                )}
                {unitDetails.construction_status?.toString().replace(/_/g, ' ')}
              </p>
            </div>
            {sale?.crm_status ? (
              <div className={styles['details-info-item']}>
                <label>{t('purchaseStatus')}</label>
                <p>
                  {sale.crm_status === 'contracted' ? (
                    <span
                      className={`${styles['status']} ${styles['completed']}`}
                    ></span>
                  ) : (
                    <span
                      className={`${styles['status']} ${styles['on-going']}`}
                    ></span>
                  )}
                  {sale.crm_status}
                </p>
              </div>
            ) : null}
            {/* {unitDetails.delivery_date ? (
              <div className={styles['details-info-item']}>
                <label>{t('deliveryDate')}</label>
                <p>
                  {t('q')}
                  {getYearQuarter(unitDetails.delivery_date)}
                </p>
              </div>
            ) : null} */}
          </div>
        )}
        {unitPage === 'unit-payments' && unitInstallments && (
          <PaymentProgress
            unitInstallments={unitInstallments}
            unitId={unitDetails?.id}
          />
        )}
        <div className={styles['details-info-buttons']}>
          {unitPage === 'my-units' || unitPage === 'unit-payments' ? (
            <NavyButton
              asLink
              to={
                unitPage === 'my-units'
                  ? ROUTES.Pre_Delivery_Unit.getPath?.(String(unitDetails?.id))
                  : ROUTES.Pre_Delivery_Payment.getPath?.(
                      String(unitDetails?.id)
                    )
              }
            >
              {t('viewDetails')}
            </NavyButton>
          ) : null}
          {unitPage === 'my-units' && sale?.crm_status !== 'contracted' ? (
            <GoldButton
              asLink
              to={ROUTES.Pre_Delivery_Sign_Contract.getPath?.(
                String(unitDetails?.id)
              )}
            >
              {t('Sign Contract')}
            </GoldButton>
          ) : null}
          {unitPage === 'unit-details' ? (
            <>
              {showPreDeliveryPay && (
                <GoldButton
                  asLink
                  to={ROUTES.Pre_Delivery_Payment.getPath?.(
                    String(unitDetails?.id)
                  )}
                >
                  {t('paymentDetails')}
                </GoldButton>
              )}
              {sale?.crm_status !== 'contracted' ? (
                <NavyButton
                  asLink
                  to={ROUTES.Pre_Delivery_Sign_Contract.getPath?.(
                    String(unitDetails?.id)
                  )}
                >
                  {t('Sign Contract')}
                </NavyButton>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      {hidePaymentImageLink ? null : (
        <Link
          className={styles['details-image']}
          to={
            unitPage === 'my-units'
              ? ROUTES.Pre_Delivery_Unit.getPath?.(String(unitDetails?.id))
              : ROUTES.Pre_Delivery_Payment.getPath?.(String(unitDetails?.id))
          }
        >
          <img
            src={unitDetails.cover_image ?? unitImagePlaceHolder}
            alt="unit"
            loading="lazy"
          />
          {timeLeft && (
            <div className={styles['countdown']}>
              {t('deliveryIn', {
                years: timeLeft.years,
                months: timeLeft.months,
                days: timeLeft.days,
              })}
            </div>
          )}
          <div className={styles['card-overlay']}>{t('viewDetails')}</div>
        </Link>
      )}
    </div>
  );
}

export default MyUnitCard;
