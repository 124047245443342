/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { NavyButton } from '../common/button/button';
import styles from './side-nav-bar.module.scss';
import RightArrowIcon from '../../assets/icons/right-nav-arrow.svg?react';
import LeftArrowIcon from '../../assets/icons/left-arrow.svg?react';
import countriesImage from '../../assets/images/nav-contries.webp';
import closeIcon from '../../assets/icons/close.svg';
import LoveIcon from '../../assets/icons/love.svg';
import InfoIcon from '../../assets/icons/info.svg';
import { Country as CountryUtilities } from '../../utils/country.utils';
import { Country as CountryInterface } from '../../definitions/interfaces/country.interface';
import { USER_CONTEXT } from '../../contexts/user-context';
import {
  Authentication,
  getDoneReservationSales,
} from '../../utils/authentication.utils';
import {
  LanguageDropdown,
  SelectDropdown,
  Tooltip,
} from '@orascom/common-components';
import { UserReservationSale } from '../../definitions/interfaces/common.interface';
import { CurrencyContext } from '../../contexts/currency.context';
import {
  analyticsSideBarCountriesCustomEvent,
  analyticsSideBarCountrySelectionCustomEvent,
  analyticsSideBarDestinationSelectionCustomEvent,
  analyticsSideBarInvestmentCustomEvent,
} from '../../utils/analytics-events';
import { showPreDeliveryLangs } from '../../definitions/consts/envConstants';
import { CURRENCIES } from '../../utils/exchange.rates.utils';
import { CommonEventParameters } from '@orascom/utils';

/* eslint-disable-next-line */
export interface SideNavBarProps {
  isSidebar: boolean;
  openSidebar: (isSidebarState: boolean) => void;
  toggleSubMenuSideBarState: (isSubMenuSidebarState: boolean) => void;
  handleShowModal: () => void;
}

export function SideNavBar(props: Readonly<SideNavBarProps>) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<CountryInterface[]>([]);
  const UserContext = useContext(USER_CONTEXT);
  const navigate = useNavigate();
  const [showCurrencyTooltip, setShowCurrencyTooltip] = useState(false);
  const subMenuNavBars = Array.from(document.querySelectorAll('.sub-menu-nav'));
  const [doneSales, setDoneSales] = useState<UserReservationSale[]>([]);
  const { setActiveCurrency, disableCurrencySetting } =
    useContext(CurrencyContext);
  const location = useLocation();

  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem('currency') as string) || {
      label: 'EGP',
      value: 1,
    }
  );

  useEffect(() => {
    if (disableCurrencySetting.disabled) {
      setSelectedCurrency({
        label: 'USD',
        value: 1,
      });
    }
  }, [disableCurrencySetting.disabled]);

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  const handleShowSubMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (!target.matches('ul')) {
      if (e.currentTarget.classList.contains('sub-menu-nav--active')) {
        e.currentTarget.classList.remove('sub-menu-nav--active');
        props.toggleSubMenuSideBarState(false);
      } else {
        subMenuNavBars.forEach((subMenu) => {
          subMenu.classList.remove('sub-menu-nav--active');
        });
        e.currentTarget.classList.add('sub-menu-nav--active');
        props.toggleSubMenuSideBarState(true);
      }
    }
  };

  const handleLinksClick = () => {
    props.openSidebar(false);
    subMenuNavBars.forEach((subMenu) => {
      subMenu.classList.remove('sub-menu-nav--active');
    });
  };

  useEffect(() => {
    CountryUtilities.getCountries()
      .then((res) => setCountries(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (UserContext?.userReservationDetails) {
      setDoneSales(
        getDoneReservationSales(UserContext?.userReservationDetails)
      );
    }
  }, [UserContext?.userReservationDetails]);

  const currencyOptions = CURRENCIES.map((cur, ind) => ({
    label: cur,
    value: ind,
  }));

  const analyticsEventParams: CommonEventParameters = {
    userId: UserContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  const handleEvent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleShowSubMenu(e);
    analyticsSideBarCountriesCustomEvent(analyticsEventParams);
  };
  return (
    <header className={styles['navigation']}>
      <div
        className={`${styles['side-nav-bar']}  ${
          props.isSidebar && styles['side-nav-bar--active']
        } `}
      >
        <div className={styles['navigation-header']}>
          <button type="button" onClick={() => handleLinksClick()}>
            {props.isSidebar && <img src={closeIcon} alt="close-icon" />}
          </button>
          <div className={styles['navigation-header-contact-us']}>
            <NavyButton onClick={props.handleShowModal}>
              {t('talkToSales')}
            </NavyButton>
          </div>
        </div>
        <div className={styles['navigation-dropdowns']}>
          {showPreDeliveryLangs && <LanguageDropdown />}
          <div className={styles['navigation-dropdowns-currency-wrapper']}>
            <SelectDropdown
              options={currencyOptions}
              placeholder={selectedCurrency.label}
              defaultValue={selectedCurrency}
              // TODO change this after implementing localization
              onChange={(val) => {
                if (val) {
                  setActiveCurrency(val.label);
                  setSelectedCurrency(val);
                }
              }}
              isSearchable={false}
              disabled={disableCurrencySetting.disabled}
              selectedOption={selectedCurrency}
            />
            {disableCurrencySetting.info && (
              <div>
                <button onClick={() => setShowCurrencyTooltip(true)}>
                  <img src={InfoIcon} alt="info-icon" />
                </button>
                {showCurrencyTooltip && (
                  <Tooltip
                    position="bottom"
                    onClose={() => setShowCurrencyTooltip(false)}
                    className="side-nav__tooltip"
                  >
                    <span>{disableCurrencySetting.info}</span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>

          <Link
            to={ROUTES.WishList.path}
            className={styles['wishlist']}
            onClick={() => handleLinksClick()}
          >
            <img src={LoveIcon} alt="heart icon" />
            {t('wishList')}
          </Link>
        </div>
        <nav className={styles['navigation-links']}>
          <button
            className={`${styles['navigation-links-menu']} sub-menu-nav`}
            onClick={(e) => handleEvent(e)}
          >
            <button
              className={`${styles['navigation-link']} sub-menu-link`}
              type="button"
            >
              <LeftArrowIcon className="svg--left" />
              {t('countries')}
              <RightArrowIcon className="svg--right" />
            </button>
            <div className={`${styles['navigation-sub-menu']} sub-menu`}>
              <ul className={styles['navigation-sub-menu-list']}>
                {countries.map((country) => (
                  <li key={country.id}>
                    <NavLink
                      className={styles['navigation-link']}
                      to={ROUTES.Country.getPath?.(country.slug, country.id)}
                      onClick={() => {
                        analyticsSideBarCountrySelectionCustomEvent({
                          name: country.slug,
                        });
                        handleLinksClick();
                      }}
                    >
                      {country.name}
                    </NavLink>
                  </li>
                ))}
              </ul>

              <div className={styles['navigation-sub-menu-image']}>
                <img src={countriesImage} alt="countries" loading="lazy" />
              </div>
            </div>
          </button>
          <button
            className={`${styles['navigation-links-menu']} sub-menu-nav`}
            onClick={(e) => handleEvent(e)}
          >
            <button
              className={`${styles['navigation-link']} sub-menu-link`}
              type="button"
            >
              <LeftArrowIcon className="svg--left" />
              {t('destinations')}
              <RightArrowIcon className="svg--right" />
            </button>
            <div
              className={`${styles['navigation-sub-menu']} sub-menu`}
              style={{
                top: 112 - window.scrollY,
              }}
            >
              <ul className={styles['navigation-sub-menu-list']}>
                {countries.map((country) => (
                  <li key={country.id}>
                    <div
                      className={
                        styles['navigation-sub-menu-list--destination']
                      }
                    >
                      <label htmlFor="">{country.name}</label>
                      <ul>
                        {country.destinations.map((countryDestination) => (
                          <li key={countryDestination.id}>
                            <NavLink
                              className={styles['navigation-link']}
                              to={ROUTES.Destination.getPath?.(
                                countryDestination.slug
                              )}
                              onClick={() => {
                                analyticsSideBarDestinationSelectionCustomEvent(
                                  analyticsEventParams
                                );
                                handleLinksClick();
                              }}
                            >
                              {countryDestination.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>

              <div className={styles['navigation-sub-menu-image']}>
                <img src={countriesImage} alt="countries" />
              </div>
            </div>
          </button>

          <NavLink
            className={styles['navigation-link']}
            to={ROUTES.Investment.path}
            onClick={() => {
              handleLinksClick();
              analyticsSideBarInvestmentCustomEvent(analyticsEventParams);
            }}
          >
            {t('investment')}
          </NavLink>
          <button
            className={`${styles['navigation-links-menu']} sub-menu-nav`}
            onClick={(e) => handleEvent(e)}
          >
            <button
              className={`${styles['navigation-link']} sub-menu-link`}
              type="button"
            >
              <LeftArrowIcon className="svg--left" />
              {t('aboutUs')}
              <RightArrowIcon className="svg--right" />
            </button>
            <div
              className={`${styles['navigation-sub-menu']} sub-menu`}
              style={{
                top: 112 - window.scrollY,
              }}
            >
              <ul className={styles['navigation-sub-menu-list']}>
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.AboutUs.path}
                    onClick={() => handleLinksClick()}
                  >
                    <>{t('aboutOrascomDevelopments')}</>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.ContactUs.path}
                    onClick={() => handleLinksClick()}
                  >
                    <>{t('contactUs')}</>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.FAQ.path}
                    onClick={() => handleLinksClick()}
                  >
                    <>{t('faqs')}</>
                  </NavLink>
                </li>
              </ul>

              <div className={styles['navigation-sub-menu-image']}>
                <img src={countriesImage} alt="countries" />
              </div>
            </div>
          </button>
          <NavLink
            to={ROUTES.NewsEvents.path}
            className={styles['navigation-link']}
            onClick={() => handleLinksClick()}
          >
            {t('newsAndEvents')}
          </NavLink>
          {UserContext.user ? (
            <>
              <NavLink
                to={ROUTES.WishList.path}
                className={styles['navigation-link']}
                onClick={() => handleLinksClick()}
              >
                {t('myWishListNav')}
              </NavLink>

              <div
                className={`${
                  (UserContext.isLoadingUserReservationDetails ||
                    !doneSales.length) &&
                  styles['navigation-link--disabled']
                } `}
              >
                <NavLink
                  to={ROUTES.Pre_Delivery_Home.path}
                  className={`${styles['navigation-link']}`}
                  onClick={() => handleLinksClick()}
                >
                  <span>{t('myPortal')}</span>
                </NavLink>
                {UserContext.isLoadingUserReservationDetails ? (
                  <span className="info-msg">
                    <img src={InfoIcon} alt="info-icon" />
                    <p>{t('loadingUnitReservations')}</p>
                  </span>
                ) : !doneSales.length ? (
                  <span className="info-msg">
                    <img src={InfoIcon} alt="info-icon" />
                    <p>{t('noReservationsFound')}</p>
                  </span>
                ) : null}
              </div>
            </>
          ) : null}
        </nav>
        {UserContext.user ? (
          <div className={styles['navigation-auth']}>
            <button
              onClick={(e) => {
                e.preventDefault();
                Authentication.logout().finally(() => {
                  if (UserContext.setUser) {
                    UserContext.setUser(null);
                    navigate(ROUTES.Home.path);
                    navigate(0);
                  }
                });
              }}
            >
              {t('logout')}
            </button>
          </div>
        ) : (
          <div className={styles['navigation-auth']}>
            <Link to={ROUTES.Login.path}>{t('login')}</Link>
            <small>{t('or')}</small>
            <Link to={ROUTES.Register.path}>{t('signUp')}</Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default SideNavBar;
