import { NavyButton } from '../../components/common/button/button';
import { useTranslation } from 'react-i18next';
import styles from './promotional-popup.module.scss';
import { ROUTES } from '../../api/routes';
import { routeSearchParamsConstructor } from '@orascom/utils';
import { Destination as DestinationInterface } from '@orascom/api-interfaces';

export interface PromotionalPopupProps {
  imgUrl: string;
  name: string;
  description: string;
  destination?: DestinationInterface;
}
export function PromotionalPopup(props: Readonly<PromotionalPopupProps>) {
  const { t } = useTranslation();
  return (
    <div className={styles['promotion-wrapper']}>
      <img
        src={props.imgUrl}
        className={styles['icon']}
        alt=""
        role="presentation"
        loading="lazy"
      />
      <div className={styles['details']}>
        <p>{props.destination?.name}</p>
        <p className={styles['title']}>{props.name}</p>
        <p>{props.description}</p>
        {props.destination && (
          <div className={styles['btn-wrapper']}>
            <NavyButton
              asLink
              to={ROUTES.AvailableUnits.path}
              onClick={() => (document.body.style.overflow = '')}
              searchParams={routeSearchParamsConstructor(
                [
                  {
                    value: props.destination?.country.slug,
                    label: props.destination?.country.name,
                  },
                ],
                [
                  {
                    value: props.destination.slug ?? '',
                    label: props.destination.name,
                  },
                ]
              ).toString()}
            >
              {t('seeAvailableUnits')}
            </NavyButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default PromotionalPopup;
