import styles from './privacy-policy.module.scss';

import { Home as HomeUtilities } from '../../utils/home.utils';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PagesDataInterface } from '../../definitions/interfaces/home.interface';
import parse from 'html-react-parser';
import { Loader } from '@orascom/common-components';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const privacyDocTitle = getDocTitle('Privacy Policy');
export function PrivacyPolicy() {
  const { t } = useTranslation();
  const [privacyPolicy, setPrivacyPolicy] = useState<PagesDataInterface>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HomeUtilities.getPrivacyPolicy()
      .then((res) => {
        setPrivacyPolicy(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="container">
      <Helmet>
        <title>{privacyDocTitle}</title>
        <meta property="og:title" content={privacyDocTitle} />
        <meta name="description" content={t('privacyMetaDescription')} />
        <meta property="og:description" content={t('privacyMetaDescription')} />
      </Helmet>
      <h1 className={styles['title']}>{t('privacyPolicy')}</h1>
      <div className={styles['wrapper']}>
        {privacyPolicy && parse(privacyPolicy.content as string)}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
