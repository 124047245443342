/**
 * @author Salma Hefnawy
 * @date 2022-10-24
 * @description implementation of destination related utility functions.
 * @filename destination.ts
 */

import {
  DestinationTestimonial,
  DestinationConstructionUpdates,
} from '../definitions/interfaces/destination.interface';
import { Destination as DestinationAPI } from '../api/destination.api';
import {
  DestinationFacilities,
  DestinationProject,
  Destination as DestinationInterface,
  UnitType,
  Location,
} from '@orascom/api-interfaces';
import { getPayloadData, handleError } from '@orascom/utils';

/**
 * group of Destination helpers functionalities.
 */
export class Destination {
  /**
   *get all Destination data.
   *
   * @static
   * @returns {Promise<DestinationInterface>}
   * @memberof Destination
   */
  public static getDestinationInfo(
    destinationSlug: string
  ): Promise<DestinationInterface> {
    return DestinationAPI.getDestinationInfo(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get destination facilities by destination id
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<DestinationFacilities[]>}
   *
   * @memberOf Destination
   */
  public static getDestinationFacilities(
    destinationId: number
  ): Promise<DestinationFacilities[]> {
    return DestinationAPI.getDestinationFacilities(destinationId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination testimonials data.
   *
   * @static
   * @returns {Promise<DestinationTestimonial[]>}
   * @memberof Destination
   */
  public static getDestinationTestimonials(
    destinationId: string
  ): Promise<DestinationTestimonial[]> {
    return DestinationAPI.getDestinationTestimonials(destinationId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination projects data.
   *
   * @static
   * @returns {Promise<DestinationProject[]>}
   * @memberof Destination
   */
  public static getDestinationProjects(
    destinationId: number
  ): Promise<DestinationProject[]> {
    return DestinationAPI.getDestinationProjects(destinationId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination projects data.
   *
   * @static
   * @returns {Promise<UnitType[]>}
   * @memberof Destination
   */
  public static getDestinationUnitTypes(
    destinationId: number
  ): Promise<UnitType[]> {
    return DestinationAPI.getDestinationUnitTypes(destinationId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination construction updates data.
   *
   * @static
   * @returns {Promise<DestinationConstructionUpdates[]>}
   * @memberof Destination
   */
  public static getDestinationConstructionUpdates(
    destinationId: number
  ): Promise<DestinationConstructionUpdates[]> {
    return DestinationAPI.getDestinationConstructionUpdates(destinationId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * gets destination locations
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<Payload<Location[]>>}
   *
   * @memberOf Destination
   */
  public static getDestinationLocations(
    destinationId: number
  ): Promise<Location[]> {
    return DestinationAPI.getDestinationLocations(destinationId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
