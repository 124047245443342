/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import Header from '../components/header/header';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../api/routes';
import { UNIT_COMPARE_CONTEXT } from '../contexts/unit-comparison.context';
import { Loader, ScrollToTop } from '@orascom/common-components';
import SideNavBar from '../components/pre-delivery/side-nav-bar/side-nav-bar';
import Footer from '../components/footer/footer';
import { User } from '@orascom/api-interfaces';
import { Authentication } from '../utils/authentication.utils';
import { USER_CONTEXT } from '../contexts/user-context';
import { changeLanguage } from '@orascom/utils';
import { CurrencyContextProvider } from '../contexts/currency.context';
import ResumeReservationBanner from '../components/resume-reservation-banner/resume-reservation-banner';
import { UserReservationDetailsInterface } from '../definitions/interfaces/common.interface';
import { showPreDeliveryPay } from '../definitions/consts/envConstants';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../utils/getDocTitle';

/** Will be refactored */
window.setTimeout(() => {
  document
    .querySelectorAll('button')
    .forEach((btn) => (btn.style.visibility = 'visible'));
}, 300);

export function App() {
  const [user, setUser] = useState<User | null>(null);
  const [userReservationDetails, setUserReservationDetails] =
    useState<UserReservationDetailsInterface | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [loadingUserReservationDetails, setLoadingUserReservationDetails] =
    useState<boolean>(false);
  const [unitsToCompare, setUnitsToCompare] = useState<number[]>([]);
  const [unitsContextErrorFlag, setUnitsContextErrorFlag] =
    useState<boolean>(false);
  useEffect(() => {
    const langValue = localStorage.getItem('lang');
    if (langValue !== null) {
      changeLanguage(JSON.parse(langValue));
    } else {
      changeLanguage({ label: 'EN', value: 'en' });
    }

    const compareUnitsJson = sessionStorage.getItem('compare_units');

    if (compareUnitsJson) {
      const compareUnits = JSON.parse(compareUnitsJson);
      const unitsIds: number[] = compareUnits.map((unitId: string) =>
        Number(unitId)
      );

      setUnitsToCompare(unitsIds);
    }
  }, []);

  const addUnitToCompare = (unitId: number) => {
    if (unitsToCompare.length > 5) {
      setUnitsContextErrorFlag(true);
      setTimeout(function () {
        setUnitsContextErrorFlag(false);
      }, 3000);
      return;
    }
    sessionStorage.setItem(
      'compare_units',
      JSON.stringify([...unitsToCompare, unitId])
    );
    setUnitsToCompare((prevUnits) => [...prevUnits, unitId]);
  };

  const removeUnitFromComparison = (unitId: number) => {
    const unitToRemove = unitsToCompare.find((unit) => unit === unitId);

    if (unitToRemove) {
      sessionStorage.setItem(
        'compare_units',
        JSON.stringify(unitsToCompare.filter((unit) => unit !== unitId))
      );

      setUnitsToCompare((prevUnits) =>
        prevUnits.filter((unit) => unit !== unitId)
      );
    }
  };

  const preDeliveryPayPaths = [
    '/pre-delivery/payments',
    '/pre-delivery/payments/:unitId',
  ];

  const availableRoutes = Object.values(ROUTES).map((route) => {
    const Component = route.component;

    if (!showPreDeliveryPay) {
      if (preDeliveryPayPaths.includes(route.path)) {
        return null;
      }
    }

    if (!route.public && !user) {
      return null;
    }

    // TODO: this will be an enum.
    if (route.app === 'pre-delivery') {
      const docTitle = getDocTitle('Pre Delivery');
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <>
              <Helmet>
                <title>{docTitle}</title>
                <meta property="og:title" content={docTitle} />
                <meta name="description" content={docTitle} />
                <meta property="og:description" content={docTitle} />
              </Helmet>
              <SideNavBar side_nav={route.side_nav ?? true} />
              <Component />
            </>
          }
        />
      );
    }
    if (route.layout === 'auth' || route.layout === 'reserve-property') {
      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <>
            <ResumeReservationBanner banner_home={route?.layout === 'home'} />
            <Header
              header_transparent={route.header_transparent ?? false}
              header_home={route?.layout === 'home'}
            />

            <Component />
            <Footer />
          </>
        }
      />
    );
  });

  useEffect(() => {
    if (!user) {
      Authentication.getLoggedInUser()
        .then((currentUser) => {
          setUser(currentUser);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoadingUser(false));
    } else {
      setLoadingUser(false);
      setLoadingUserReservationDetails(true);
      Authentication.getUserReservationDetails()
        .then((reservationDetails) => {
          setUserReservationDetails(reservationDetails);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoadingUserReservationDetails(false));
    }
  }, [user]);

  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: loadingUser,
      userReservationDetails,
      setUserReservationDetails,
      isLoadingUserReservationDetails: loadingUserReservationDetails,
    }),
    [user, loadingUser, loadingUserReservationDetails]
  );
  const compareUnitsContextValue = useMemo(
    () => ({
      units: unitsToCompare,
      addUnit: addUnitToCompare,
      removeUnit: removeUnitFromComparison,
      showError: unitsContextErrorFlag,
    }),
    [
      unitsToCompare,
      addUnitToCompare,
      removeUnitFromComparison,
      unitsContextErrorFlag,
    ]
  );

  if (loadingUser) {
    return <Loader />;
  }
  return (
    <USER_CONTEXT.Provider value={userContextValue}>
      <UNIT_COMPARE_CONTEXT.Provider value={compareUnitsContextValue}>
        <CurrencyContextProvider>
          <ScrollToTop />
          <Routes>{availableRoutes}</Routes>
        </CurrencyContextProvider>
      </UNIT_COMPARE_CONTEXT.Provider>
    </USER_CONTEXT.Provider>
  );
}

export default App;
