/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { Destination as DestinationUtilities } from '../../utils/destination.utils';
import { Country as CountryUtilities } from '../../utils/country.utils';
import { Unit as UnitUtilities } from '../../utils/unit.utils';
import {
  DestinationTestimonial as DestinationTestimonialInterface,
  DestinationConstructionUpdates,
} from '../../definitions/interfaces/destination.interface';
import { CountryDestination } from '../../definitions/interfaces/country.interface';
import AboutOrascom from '../../components/common/about-orascom/about-orascom';
import DestinationLocation from '../../components/destination-location/destination-location';
import DestinationTestimonial from '../../components/destination-testimonial/destination-testimonial';
import { CardsSlider, FadeInSection, Loader } from '@orascom/common-components';
import ReachUs from '../../components/common/reach-us/reach-us';
import { GoldButton, NavyButton } from '../../components/common/button/button';
import DestinationBanner from '../../components/destination-banner/destination-banner';
import DestinationFacilities from '../../components/destination-facilities/destination-facilities';
import DestinationProjects from '../../components/destination-projects/destination-projects';
import OtherDestinations from '../../components/other-destinations/other-destinations';
import GallerySlider from '../../components/common/gallery-slider/gallery-slider';
import { routeSearchParamsConstructor } from '@orascom/utils';

import {
  UnitInterface,
  DestinationProject,
  UnitType,
  Destination as DestinationInterface,
  DestinationFacilities as DestinationFacilitiesInterface,
  Location,
  FiltersEnums,
} from '@orascom/api-interfaces';
import PropertyCardsSwiper from '../../components/common/property-cards-swiper/property-cards-swiper';
import NewsCard from '../../components/common/news-card/news-card';
import Modal from 'libs/common-components/src/lib/modal/modal';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import CompareButton from '../../components/common/compare-button/compare-button';
import styles from './destination.module.scss';
import { v4 as uuidv4 } from 'uuid';
import getDocTitle from '../../utils/getDocTitle';
import { Helmet } from 'react-helmet-async';
import PromotionalPopup from '../../components/promotional-popup/promotional-popup';
import usePromotion from '../../hooks/use-promotional-popup';

export function Destination() {
  const [destinationInfo, setDestinationInfo] =
    useState<DestinationInterface>();

  const [destinationFacilities, setDestinationFacilities] = useState<
    DestinationFacilitiesInterface[]
  >([]);
  const [destinationTestimonials, setDestinationTestimonials] =
    useState<DestinationTestimonialInterface[]>();
  const [destinationProjects, setDestinationProjects] = useState<
    DestinationProject[]
  >([]);
  const [otherDestinations, setOtherDestinations] = useState<
    CountryDestination[]
  >([]);
  const [constructionUpdates, setConstructionUpdates] = useState<
    DestinationConstructionUpdates[]
  >([]);

  const destinationSlug = useParams<{ destinationSlug: string }>()
    .destinationSlug;
  const [destinationUnitTypes, setDestinationUnitTypes] = useState<UnitType[]>(
    []
  );
  const [featuredUnits, setFeaturedUnits] = useState<UnitInterface[]>([]);
  const [resaleUnits, setResaleUnits] = useState<UnitInterface[]>([]);
  const [destinationLocations, setDestinationLocations] = useState<Location[]>(
    []
  );

  const { t } = useTranslation();
  const { handleClosePromotion, promotionalModal } = usePromotion(
    destinationInfo?.active_promotion?.id
  );

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (destinationSlug) {
      DestinationUtilities.getDestinationInfo(destinationSlug)
        .then((res) => {
          setDestinationInfo(res);

          if (res?.id) {
            DestinationUtilities.getDestinationTestimonials(res.id.toString())
              .then((testimonials) => {
                setDestinationTestimonials(testimonials);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [destinationSlug]);

  useEffect(() => {
    if (destinationInfo) {
      DestinationUtilities.getDestinationFacilities(destinationInfo.id)
        .then((res) => setDestinationFacilities(res))
        .catch((err) => console.log(err));

      DestinationUtilities.getDestinationLocations(destinationInfo.id)
        .then((response) => setDestinationLocations(response))
        .catch((err) => console.error(err));

      DestinationUtilities.getDestinationProjects(destinationInfo.id)
        .then((res) => {
          setDestinationProjects(res);
        })
        .catch((err) => {
          console.log(err);
        });

      CountryUtilities.getCountries(String(destinationInfo.country?.id))
        .then((res) => {
          const destinations = res[0].destinations.filter(
            (d) => destinationInfo.id !== d.id
          );
          if (destinations.length > 3) {
            destinations.splice(0, 3);
          }
          setOtherDestinations(destinations);
        })
        .catch((err) => console.log(err));
      DestinationUtilities.getDestinationUnitTypes(destinationInfo.id)
        .then((res) => {
          setDestinationUnitTypes(res);
        })
        .catch((err) => {
          console.log(err);
        });

      DestinationUtilities.getDestinationConstructionUpdates(destinationInfo.id)
        .then((res) => {
          setConstructionUpdates(res);
        })
        .catch((err) => {
          console.log(err);
        });

      const unitSearchParams = new URLSearchParams();

      unitSearchParams.set(
        `${FiltersEnums.DESTINATIONS}[0]`,
        destinationInfo.slug?.toString() ?? ''
      );

      if (destinationInfo.section?.tags?.length > 0) {
        UnitUtilities.getUnits(1, 10, unitSearchParams)
          .then((res) => {
            setFeaturedUnits(res.data);
          })
          .catch((err) => console.log(err));
      }

      unitSearchParams.set(FiltersEnums.ON_RESALE, '1');
      UnitUtilities.getUnits(1, 10, unitSearchParams)
        .then((res) => {
          setResaleUnits(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [destinationInfo]);

  if (!destinationInfo || !destinationTestimonials) {
    return <Loader />;
  }

  const destinationDocTitle = getDocTitle(destinationInfo.name);
  return (
    <div className={styles['destination']}>
      <Helmet>
        <title>{destinationDocTitle}</title>
        <meta property="og:title" content={destinationDocTitle} />
        <meta
          name="description"
          content={destinationInfo.welcome_description}
        />
        <meta
          property="og:description"
          content={destinationInfo.welcome_description}
        />
      </Helmet>
      <CompareButton />
      <DestinationBanner
        title={destinationInfo.welcome_title ?? ''}
        subTitle={destinationInfo.welcome_description ?? ''}
        image={destinationInfo.welcome_image ?? ''}
        logo={destinationInfo.logo}
        destinationName={destinationInfo.name ?? ''}
        destinationId={destinationInfo.slug ?? ''}
        destinationCountryName={destinationInfo.country.name ?? ''}
        destinationCountryId={destinationInfo.country.slug ?? ''}
        scrollTitle={destinationInfo.scroll_title ?? ''}
      />

      <AboutOrascom
        title={destinationInfo.about_sub_title ?? t('aboutDestination')}
        subTitle={destinationInfo.about_title ?? ''}
        description={destinationInfo.about_description ?? ''}
        aboutInfo={destinationInfo.metadata?.about ?? []}
        aboutImage={destinationInfo.about_image ?? ''}
        showAboutButton={false}
        videoUrl={destinationInfo.video}
        playlistUrl={destinationInfo.video_playlist}
      />
      <div className={styles['destination__testimonials']}>
        <h1 className="orascom__title">{destinationInfo.testimonial_title}</h1>
        <h2 className="orascom__sub-title">
          {`${t('whatOurBelovedResidentsSayAbout')} ${destinationInfo.name}`}
        </h2>
        <CardsSlider slidesPerView={1}>
          {destinationTestimonials.map((testimonial) => (
            <DestinationTestimonial
              key={testimonial.id}
              id={testimonial.id}
              name={testimonial.name}
              image={testimonial.image}
              position={testimonial.position}
              review={testimonial.review}
            />
          ))}
        </CardsSlider>
      </div>
      <DestinationLocation
        locationTitle={destinationInfo.location_title}
        locationDescription={destinationInfo.location_description}
        locationImage={destinationInfo.location_image}
        locations={destinationLocations}
        masterPlanTitle={destinationInfo.masterplan_title}
        masterPlanSubTitle={destinationInfo.masterplan_sub_title}
        masterPlanSubDescription={destinationInfo.masterplan_description}
        masterPlanImage={destinationInfo.masterplan_image}
        masterPlanBrochure={destinationInfo.masterplan_brochure}
        destinationSlug={destinationInfo.slug ?? ''}
      />
      <DestinationProjects
        projects={destinationProjects}
        title={destinationInfo.projects_sub_title}
        subTitle={destinationInfo.projects_title}
        destinationName={destinationInfo.name ?? ''}
        destinationId={destinationInfo.slug ?? ''}
        destinationCountryName={destinationInfo.country.name ?? ''}
        destinationCountryId={destinationInfo.country.slug ?? ''}
      />
      {/* <DestinationUnitTypes
        destinationName={destinationInfo.name as string}
        destinationId={destinationInfo.id}
        country={destinationInfo.country}
        unitTypes={destinationUnitTypes}
      /> */}
      {destinationFacilities?.length ? (
        <FadeInSection>
          <DestinationFacilities
            title={destinationInfo.facilities_sub_title}
            subtitle={destinationInfo.facilities_title}
            image={destinationInfo.facilities_image}
            destinationFacilities={destinationFacilities}
          />
        </FadeInSection>
      ) : null}
      {(featuredUnits?.length ||
        (destinationInfo.show_resale && resaleUnits?.length)) && (
        <div className={styles['explore-units']}>
          <h4 className="orascom__title">{t('browseProperties')}</h4>
          <h5 className="orascom__sub-title">{`${t('exploreUnitsIn')} ${
            destinationInfo.name
          }`}</h5>
          {destinationInfo.section?.tags?.length > 0 &&
          featuredUnits?.length ? (
            <PropertyCardsSwiper
              title={destinationInfo.section.title}
              units={featuredUnits}
              viewAllLink={ROUTES.AvailableUnits.path}
              searchParams={routeSearchParamsConstructor(
                undefined,
                [
                  {
                    label: destinationInfo.name,
                    value: destinationInfo.id,
                  },
                ],
                undefined,
                undefined,
                undefined,
                destinationInfo.section.tags
              ).toString()}
            />
          ) : null}
          {destinationInfo.show_resale && resaleUnits?.length > 0 ? (
            <PropertyCardsSwiper
              title={t('propertiesForResale')}
              units={resaleUnits}
              viewAllLink={ROUTES.AvailableUnits.path}
              searchParams={routeSearchParamsConstructor(
                undefined,
                [
                  {
                    label: destinationInfo.name,
                    value: destinationInfo.id,
                  },
                ],
                undefined,
                undefined,
                true
              ).toString()}
            />
          ) : null}
        </div>
      )}
      {destinationInfo.gallery.length ? (
        <div className={styles['destination__gallery']}>
          <div className={styles['destination__gallery-headers']}>
            <h1 className="orascom__title">{t('imageGallery')}</h1>
            <h2 className="orascom__sub-title">
              {destinationInfo.gallery_title}
            </h2>
          </div>
          <GallerySlider>
            {destinationInfo.gallery.map((galleryImageUrl) => (
              <img
                src={galleryImageUrl}
                alt="gallery"
                key={uuidv4()}
                loading="lazy"
              />
            ))}
          </GallerySlider>
        </div>
      ) : null}
      {constructionUpdates.length ? (
        <div className={styles['construction-updates']}>
          <h1 className="orascom__title">{t('constructionUpdates')}</h1>
          <h2 className="orascom__sub-title">
            {destinationInfo.construction_updates_title}
          </h2>

          <CardsSlider slidesPerView={3.5}>
            {constructionUpdates.map((item) => (
              <NewsCard
                key={uuidv4()}
                image={item.image}
                date={item.date}
                title={item.title}
              />
            ))}
          </CardsSlider>
        </div>
      ) : null}
      <ReachUs
        title={`${t('interestedIn')} ${destinationInfo.name}`}
        subTitle={t('registerInterest')}
        description={t('findWaysToEnjoyResidentialLife')}
        primaryButton={
          <GoldButton onClick={handleShowModal}>
            {t('registerInterest')}
          </GoldButton>
        }
        secondaryButton={
          <NavyButton
            asLink
            to={ROUTES.AvailableUnits.path}
            searchParams={routeSearchParamsConstructor(
              [
                {
                  value:
                    destinationInfo.country.slug ?? destinationInfo.country.id,
                  label: destinationInfo.country.name,
                },
              ],
              [
                {
                  value: destinationInfo.slug ?? destinationInfo.id,
                  label: destinationInfo.name || '',
                },
              ]
            ).toString()}
          >
            {t('seeAvailableUnits')}
          </NavyButton>
        }
      />
      {otherDestinations.length ? (
        <OtherDestinations destinations={otherDestinations} />
      ) : null}
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title={t('submitYourInterest')}
      >
        <ContactUsForm
          title={t('leaveYourContactInfo')}
          destination={destinationInfo.slug}
          country={destinationInfo.country.id}
          interestForm
          onCloseModal={handleCloseModal}
        />
      </Modal>

      {destinationInfo.active_promotion && (
        <Modal
          show={promotionalModal}
          onClose={handleClosePromotion}
          customMaxWidth={665}
          hideHeaderBorder
          customBackground="-webkit-linear-gradient(85.33deg,#f5f7fa 0%,#c7cdd9 100.68%)"
          overlapHeader
        >
          <PromotionalPopup
            imgUrl={destinationInfo.active_promotion.image}
            description={destinationInfo.active_promotion.description}
            name={destinationInfo.active_promotion.name}
            destination={destinationInfo}
          />
        </Modal>
      )}
    </div>
  );
}

export default Destination;
