import styles from './terms-and-conditions.module.scss';

import { Home as HomeUtilities } from '../../utils/home.utils';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PagesDataInterface } from '../../definitions/interfaces/home.interface';
import parse from 'html-react-parser';
import { Loader } from '@orascom/common-components';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const termsDocTitle = getDocTitle('Terms & Conditions');

export function TermsAndConditions() {
  const { t } = useTranslation();
  const [termsAndConditions, setTermsAndConditions] =
    useState<PagesDataInterface>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HomeUtilities.getTermsAndConditions()
      .then((res) => {
        setTermsAndConditions(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="container">
      <Helmet>
        <title>{termsDocTitle}</title>
        <meta property="og:title" content={termsDocTitle} />
        <meta name="description" content={t('termsMetaDescription')} />
        <meta property="og:description" content={t('termsMetaDescription')} />
      </Helmet>
      <h1 className={styles['title']}>{t('termsAndConditions')}</h1>
      <div className={styles['wrapper']}>
        {termsAndConditions && parse(termsAndConditions.content as string)}
      </div>
    </div>
  );
}

export default TermsAndConditions;
