import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter as FilterUtils } from '../../../utils/filter-utils';
import { useNavigate } from 'react-router-dom';
import {
  mapCountriesResponseToFilterValues,
  mapDestinationsResponseToFilterValues,
  mapResponseToFilterValues,
  routeSearchParamsConstructor,
} from '@orascom/utils';
import { ROUTES } from '../../../api/routes';
import {
  FiltersEnums,
  OptionValue,
  PriceValue,
  UnitFilters,
} from '@orascom/api-interfaces';
import { MultiSelectDropdown } from '@orascom/common-components';

import './search-bar.scss';
import { usePriceSlider } from '../../../pages/available-units/use-price-slider';
import useCurrencyContext from '../../../hooks/use-currency-context';
import { MinMaxDropdown } from '../min-max-dropdown/min-max-dropdown';

export function SearchBar() {
  const [selectedCountries, setSelectedCountries] = useState<OptionValue[]>();
  const [selectedDestination, setSelectedDestination] =
    useState<OptionValue[]>();
  const [selectedUnitTypes, setSelectedUnitTypes] = useState<OptionValue[]>();

  const [unitFilters, setUnitFilters] = useState<UnitFilters>();
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const { isLoading: isLoadingCurrency } = useCurrencyContext();

  const { priceSlider, setPriceSlider } = usePriceSlider(unitFilters);
  function setPriceValue(priceValue: PriceValue) {
    setPriceSlider({
      ...priceSlider,
      price: priceValue,
    });
  }

  const navigate = useNavigate();
  const { t } = useTranslation();

  const getAvailableFilters = (filterSearchParams: URLSearchParams) => {
    setIsLoadingFilters(true);
    FilterUtils.getUnitFilters(filterSearchParams)
      .then((res) => {
        setUnitFilters(res);
        setIsLoadingFilters(false);
      })
      .catch(() => setIsLoadingFilters(false));
  };

  useEffect(() => {
    setSelectedDestination(undefined);
    setSelectedUnitTypes(undefined);
  }, [selectedCountries]);

  useEffect(() => {
    const params: Record<string, string> = {};
    function extendParams(
      options: OptionValue<string | number>[],
      key: string
    ) {
      options.forEach((option, index) => {
        params[`${key}[${index}]`] = option.value.toString();
      });
    }

    if (selectedCountries) {
      extendParams(selectedCountries, FiltersEnums.COUNTRIES);
    }

    if (selectedDestination) {
      extendParams(selectedDestination, FiltersEnums.DESTINATIONS);
    }

    if (selectedUnitTypes) {
      extendParams(selectedUnitTypes, FiltersEnums.UNIT_TYPES);
    }

    const filtersSearchParams = new URLSearchParams(params);

    getAvailableFilters(filtersSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries, selectedDestination, selectedUnitTypes]);

  function onExploreProperty() {
    const filtersParams = routeSearchParamsConstructor(
      selectedCountries,
      selectedDestination,
      undefined,
      selectedUnitTypes
    );

    if (priceSlider.price.min) {
      const isDefaultMin = priceSlider.price.min === priceSlider.minRange;
      const isDefaultMax = priceSlider.price.max === priceSlider.maxRange;

      const isDefaultPrice = isDefaultMin && isDefaultMax;

      // navigate with price values if they aren't the default ones
      if (!isDefaultPrice) {
        filtersParams.set(
          FiltersEnums.MIN_PRICE,
          priceSlider.price.min.toString()
        );
        filtersParams.set(
          FiltersEnums.MAX_PRICE,
          priceSlider.price.max.toString()
        );
        filtersParams.set(FiltersEnums.CURRENCY, priceSlider.price.currency);
      }
    }

    navigate({
      pathname: ROUTES.AvailableUnits.path,
      search: filtersParams.toString(),
    });
  }
  return (
    <div>
      <div className="search-bar">
        <MultiSelectDropdown
          isLoading={isLoadingFilters}
          options={mapCountriesResponseToFilterValues(
            unitFilters?.countries ?? []
          )}
          placeholder={t('country')}
          onChange={(val) => setSelectedCountries(val)}
          className="search-bar__select"
        />
        <div className="search-bar__separator" />
        <MultiSelectDropdown
          isLoading={isLoadingFilters}
          options={mapDestinationsResponseToFilterValues(
            unitFilters?.destinations ?? []
          )}
          placeholder={t('destination')}
          onChange={(val) => setSelectedDestination(val)}
          className="search-bar__select"
          keyValue={selectedCountries?.length}
        />
        <div className="search-bar__separator" />
        <MultiSelectDropdown
          isLoading={isLoadingFilters}
          options={mapResponseToFilterValues(unitFilters?.unit_types ?? [])}
          placeholder={t('unitType')}
          onChange={(val) => setSelectedUnitTypes(val)}
          className="search-bar__select"
          keyValue={selectedCountries?.length}
        />
        <div className="search-bar__separator" />
        <MinMaxDropdown
          priceSlider={priceSlider}
          setPriceValue={setPriceValue}
          disabled={isLoadingCurrency || isLoadingFilters}
        />

        <div className="search-bar__separator" />

        <button onClick={onExploreProperty} className="btn btn--gold-static">
          {t('exploreProperties')}
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
