/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable import/no-extraneous-dependencies */
import styles from './unit-payment-breakdown.module.scss';
import Numeral from 'numeral';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpArrowIcon from '../../../assets/icons/arrow-up-large.svg?react';
import greenBulletIcon from '../../../assets/icons/green-bullet.svg';
import grayBulletIcon from '../../../assets/icons/gray-bullet.svg';
import { CountriesEnum, SaleInstallment } from '@orascom/api-interfaces';
import { getYearQuarter } from '@orascom/utils';
import { v4 as uuidv4 } from 'uuid';

interface UnitPaymentBreakdownProps {
  installments: SaleInstallment[];
  unitCountry?: string;
}
export const UnitPaymentBreakdown = React.forwardRef(
  (
    props: UnitPaymentBreakdownProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const [showPaymentBreakdown, setShowPaymentBreakdown] = useState(true);

    const { installments, unitCountry } = props;

    const paymentBreakdown = useRef<HTMLDivElement | null>(null);

    const handlePaymentBreakdown = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      setShowPaymentBreakdown(!showPaymentBreakdown);
      /** select the sub menu ul list and set its styles to show and hide. */

      if (
        paymentBreakdown?.current &&
        paymentBreakdown.current.style.maxHeight !== '0px'
      ) {
        paymentBreakdown.current.style.maxHeight = '0px';
        paymentBreakdown.current.style.marginBottom = '0';
      } else if (
        paymentBreakdown?.current &&
        paymentBreakdown.current.style.maxHeight === '0px'
      ) {
        paymentBreakdown.current.style.maxHeight = 'fit-content';
        paymentBreakdown.current.style.marginBottom = '30px';
      }
    };

    const hasRates = installments?.some(
      (installment) => installment.rates_percent
    );
    const hasClubhouseFees = installments?.some(
      (installment) => installment.clubhouse_fees
    );
    const hasMaintenanceFees = installments?.some(
      (installment) => installment.maintenance_fees
    );

    let columnCount = 0;
    let tableStyling = '';

    if (hasMaintenanceFees) {
      columnCount++;
    }
    if (hasClubhouseFees) {
      columnCount++;
    }
    if (hasRates) {
      columnCount++;
    }
    switch (columnCount) {
      case 1:
        tableStyling = 'tr--five-columns';
        break;
      case 2:
        tableStyling = 'tr--six-columns';
        break;
      case 3:
        tableStyling = 'tr--seven-columns';
        break;
    }
    // const currentTableData = useMemo(() => {
    //   if (unitPaymentBreakdown) {
    //     const firstPageIndex =
    //       (currentPage - 1) * unitPaymentBreakdown.meta.per_page;
    //     const lastPageIndex =
    //       firstPageIndex + unitPaymentBreakdown.meta.per_page;
    //     return (
    //       unitPaymentBreakdown &&
    //       unitPaymentBreakdown.data.slice(firstPageIndex, lastPageIndex)
    //     );
    //   }
    // }, [unitPaymentBreakdown, currentPage]);

    // if (!unitPaymentBreakdown || !currentTableData) {
    //   return <Loader />;
    // }
    return (
      <div className={styles['unit-payment-breakdown']} ref={ref}>
        <div className={styles['background']} />
        <button onClick={(e) => handlePaymentBreakdown(e)}>
          <h2 className={`${!showPaymentBreakdown && 'closed'}`}>
            {t('paymentPlanBreakdown')} <UpArrowIcon />
          </h2>
        </button>
        <div ref={paymentBreakdown} className={styles['table']}>
          <table>
            <thead>
              <tr className={tableStyling}>
                <th>No.</th>
                {unitCountry !== CountriesEnum.MONTENEGRO && (
                  <th>Payment Type</th>
                )}
                <th>Due Date</th>
                <th>Installment</th>
                {unitCountry === CountriesEnum.MONTENEGRO && (
                  <th>Payment Name</th>
                )}
                {hasRates && <th>Rates</th>}
                {hasClubhouseFees && <th>Clubhouse Fees</th>}
                {hasMaintenanceFees && <th>Maintenance</th>}
                {/* <th>Total</th> */}
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {installments?.map((item, index) => {
                const ratesPercent = item.rates_percent;
                const clubhouseFees = item.clubhouse_fees;
                const maintenanceFees = item.maintenance_fees;
                return (
                  <tr className={tableStyling} key={uuidv4()}>
                    <td>{index}</td>
                    {unitCountry !== CountriesEnum.MONTENEGRO && (
                      <td>{item.type}</td>
                    )}
                    <td className={styles['table-date']}>
                      <span>
                        {t('q')}
                        {getYearQuarter(item.payment_date)}
                      </span>
                      <p>
                        {new Date(item.payment_date * 1000).toLocaleDateString(
                          'en-GB',
                          {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }
                        )}
                      </p>
                    </td>
                    {item.payment_value ? (
                      <td>
                        {Numeral(item.payment_value).format('0,0')}{' '}
                        <span>{item.currency}</span>
                      </td>
                    ) : null}
                    {unitCountry === CountriesEnum.MONTENEGRO && (
                      <td>{item.payment_name}</td>
                    )}

                    {ratesPercent && ratesPercent > 0 ? (
                      <td>{`${ratesPercent + '%'}`} </td>
                    ) : null}

                    {clubhouseFees && clubhouseFees > 0 ? (
                      <td>
                        {Numeral(clubhouseFees).format('0,0')}{' '}
                        <span>{item.currency}</span>
                      </td>
                    ) : null}

                    {maintenanceFees && maintenanceFees > 0 ? (
                      <td>
                        {Numeral(maintenanceFees).format('0,0')}{' '}
                        <span>{item.currency}</span>
                      </td>
                    ) : null}

                    {/*
                    <td className={styles['table-total']}>
                      {Numeral(item.total_payment_value).format('0,0')}{' '}
                      <span>{item.currency}</span>
                    </td>
                    */}
                    <td className={styles['table-status']}>
                      {item.paid ? (
                        <>
                          {' '}
                          <img
                            src={greenBulletIcon}
                            alt=""
                            role="presentation"
                          />{' '}
                          <span>paid</span>
                        </>
                      ) : (
                        <>
                          <img
                            src={grayBulletIcon}
                            alt=""
                            role="presentation"
                          />{' '}
                          <span>unpaid</span>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            totalCount={unitPaymentBreakdown.meta.total}
            pageSize={unitPaymentBreakdown.meta.per_page}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
        </div>
      </div>
    );
  }
);

export default UnitPaymentBreakdown;
