/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable import/no-extraneous-dependencies */
import { v4 as uuidv4 } from 'uuid';
import styles from './about-orascom.module.scss';
import { AboutInfo as AboutInfoInterface } from '@orascom/api-interfaces';
import AboutOrascomImage from '../../../assets/images/about-orascom.webp';
import { NavyButton } from '../button/button';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../api/routes';
import { FadeInSection, VideoPlayer } from '@orascom/common-components';
import { formatInfoItem } from '../../../utils/common.utils';
import { analyticsMoreAboutDestinationCustomEvent } from 'apps/orascom-shopper-app/src/utils/analytics-events';
import arrowIcon from '../../../assets/icons/right-arrow.svg';
import { Link, useLocation } from 'react-router-dom';
import { USER_CONTEXT } from 'apps/orascom-shopper-app/src/contexts/user-context';
import { useContext } from 'react';

/* eslint-disable-next-line */
export interface AboutOrascomProps {
  title: string;
  subTitle: string;
  subTitle2?: string;
  description: string;
  description2?: string;
  aboutInfo?: Partial<AboutInfoInterface>[];
  showAboutButton: boolean;
  aboutImage?: string;
  buttonText?: string;
  buttonLink?: string;
  aboutPage?: boolean;
  aboutPageButton?: boolean;
  imageFirst?: boolean;
  videoUrl?: string;
  playlistUrl?: string;
}

export function AboutOrascom(props: Readonly<AboutOrascomProps>) {
  const { t } = useTranslation();
  const location = useLocation();
  const userContext = useContext(USER_CONTEXT);
  const {
    title,
    subTitle,
    subTitle2,
    description,
    description2,
    aboutInfo,
    aboutImage,
    showAboutButton,
    buttonText,
    buttonLink,
    aboutPage,
    aboutPageButton,
  } = props;

  const wrapperClasses = `${styles['about-orascom']} container ${
    props.imageFirst ? styles['image-first'] : ''
  }`;

  return (
    <FadeInSection>
      <div className={wrapperClasses}>
        <div className={styles['about-orascom__info']}>
          <h1
            className={`${styles['about-orascom__info-title']} orascom__title`}
          >
            {title}
          </h1>
          <h2
            className={`${styles['about-orascom__info-sub-title']} orascom__sub-title`}
          >
            {subTitle}
            <br></br>
            {subTitle2 && (
              <span
                className={`${styles['about-orascom__info-sub-title']} orascom__sub-title`}
              >
                {subTitle2}
              </span>
            )}
          </h2>
          <p className={styles['about-orascom__info-desc']}>{description}</p>
          <p className={styles['about-orascom__info-desc2']}>{description2}</p>
          {props.videoUrl ? (
            <div className={styles['about-orascom__video--responsive']}>
              <VideoPlayer videoUrl={props.videoUrl} />
              {props.playlistUrl && (
                <Link
                  target="_blank"
                  to={props.playlistUrl}
                  className={styles['playlist-btn']}
                >
                  {t('seePlaylist')}
                  <img src={arrowIcon} alt="" role="presentation" />
                </Link>
              )}
            </div>
          ) : (
            <div className={styles['about-orascom__image--responsive']}>
              <img
                src={aboutImage ?? AboutOrascomImage}
                alt=""
                loading="lazy"
              />
            </div>
          )}
          {aboutInfo && (
            <div className={styles['about-orascom__info-details']}>
              {aboutInfo?.map((aboutInfoItem) => {
                return (
                  <div
                    className={styles['about-orascom__info-details-item']}
                    key={uuidv4()}
                  >
                    <p className="orascom__sub-title">
                      {formatInfoItem(
                        String(aboutInfoItem.value),
                        String(aboutInfoItem.type),
                        String(aboutInfoItem.unit)
                      )}
                    </p>
                    <p>{aboutInfoItem.name}</p>
                  </div>
                );
              })}
            </div>
          )}
          {showAboutButton && (
            <NavyButton
              asLink
              to={buttonLink ?? ROUTES.AboutUs.path}
              onClick={() =>
                analyticsMoreAboutDestinationCustomEvent({
                  name: `${buttonText}`,
                  userId: userContext.user?.id.toString(),
                  timestamp: Date.now().toString(),
                  portal: 'Shopper',
                  pageName: location.pathname,
                })
              }
            >
              {buttonText ?? t('knowMore')}
            </NavyButton>
          )}
          {aboutPage && (
            <a
              href="https://www.orascomdh.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
            >
              {t('learnMoreAboutODH')}
            </a>
          )}
          {aboutPageButton && (
            <a
              href="https://www.orascomdh.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--navy"
            >
              {t('knowMore')}
              <div className="white" />
            </a>
          )}
        </div>
        {props.videoUrl ? (
          <div className={styles['about-orascom__video']}>
            <VideoPlayer videoUrl={props.videoUrl} />
            {props.playlistUrl && (
              <Link
                target="_blank"
                to={props.playlistUrl}
                className={styles['playlist-btn']}
              >
                {t('seePlaylist')}
                <img src={arrowIcon} alt="" role="presentation" />
              </Link>
            )}
          </div>
        ) : (
          <div className={styles['about-orascom__image']}>
            <img src={aboutImage ?? AboutOrascomImage} alt="" loading="lazy" />
          </div>
        )}
      </div>
    </FadeInSection>
  );
}

export default AboutOrascom;
