import { useContext } from 'react';
import { CurrencyContext } from '../contexts/currency.context';

const useCurrencyContext = () => {
  const currencyContext = useContext(CurrencyContext);

  if (!currencyContext) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }

  return currencyContext;
};

export default useCurrencyContext;
