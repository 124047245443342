import { UnitReservationDetails } from '@orascom/api-interfaces';
import areaIcon from '../../assets/icons/area.svg';
import bedroomIcon from '../../assets/icons/bedroom.svg';
import bathroomIcon from '../../assets/icons/bathroom.svg';
import styles from './reserve-unit-card.module.scss';
import { useTranslation } from 'react-i18next';
import Numeral from 'numeral';
import { CurrencyContext } from '../../contexts/currency.context';
import { useContext } from 'react';
export interface ReserveUnitCardProps {
  unit: UnitReservationDetails;
  rowLayout?: boolean;
}

export function ReserveUnitCard({
  unit,
  rowLayout,
}: Readonly<ReserveUnitCardProps>) {
  const { t } = useTranslation();
  const { rates, currency, isLoading } = useContext(CurrencyContext);
  const displayedPrice = unit?.price;
  const displayedCurrency = unit?.currency;
  // if (rates && unit) {
  //   if (currency !== unit?.currency && !isLoading) {
  //     const rateKey = `${currency}${unit?.currency}`;
  //     displayedCurrency = currency;
  //     displayedPrice = unit?.price / Number(rates[rateKey]);
  //   }
  // }
  return (
    <div
      className={`${styles['wrapper']} ${
        rowLayout ? styles['wrapper-row'] : ''
      }`}
    >
      <img
        src={unit?.cover_image}
        alt={unit?.unit_type.name}
        className={`${styles['image']} ${rowLayout ? styles['image-row'] : ''}`}
        loading="lazy"
      />
      <div className={styles['details']}>
        <div className={styles['title']}>
          {unit?.project.name} - {unit?.project.destination.name}
        </div>
        <h6 className={styles['sub-title']}>{unit?.unit_type.name}</h6>
        <div className={styles['info']}>
          {unit?.bathrooms > 0 ? (
            <span>
              <img src={bathroomIcon} alt="" role="presentation" />
              {unit?.bathrooms}
            </span>
          ) : null}
          {unit?.bedrooms > 0 ? (
            <span>
              <img src={bedroomIcon} alt="" role="presentation" />
              {unit?.bedrooms}
            </span>
          ) : null}
          {unit?.unit_size > 0 ? (
            <span>
              <img src={areaIcon} alt="" role="presentation" />
              {unit?.unit_size} {t('m²')}
            </span>
          ) : null}
        </div>
        {!rowLayout && (
          <>
            <div className={styles['title']}>{t('originalUnitPrice')}</div>
            <h5 className={styles['price']}>
              {Numeral(displayedPrice).format('0,0')} {displayedCurrency}
            </h5>
          </>
        )}
      </div>
    </div>
  );
}

export default ReserveUnitCard;
